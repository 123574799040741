import React from "react";

function App() {
  return (
    <div
      className="w-full h-screen flex justify-center items-center overflow-hidden sm:min-h-fit scrollbar-hide"
      style={{ backgroundColor: "#EFEEED" }}
    >
      <h1 className="text-5xl text-center lg:-mt-8 md:-mt-14 sm:-mt-20">
        GONGWON CO.
      </h1>
      <div className="absolute text-lg text-center bottom-0 py-14">
        <p>Architecture, Art, Interior</p>
        <a href="mailto:&#115;&#116;&#117;&#100;&#105;&#111;&#064;&#103;&#111;&#110;&#103;&#119;&#111;&#110;&#046;&#099;&#111;">
          studio@gongwon.co
        </a>
      </div>
    </div>
  );
}

export default App;
